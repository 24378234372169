<script>
import Layout from '../../layouts/main';
import appConfig from '@/app.config';
import PageHeader from '@/components/page-header';
import AccountTransfers from '@/components/pages/accounts/account-transfers';
import { authComputed } from '@/state/helpers';
import OrderHistory from '@/components/pages/accounts/order-history';
import BtcIcon from '@/components/common/currency-icons/btc';
import EthIcon from '@/components/common/currency-icons/eth';
import CurrencyIcon from '@/components/common/currency-icons/currency';
import CoinAccountCard from '@/components/pages/home/CoinAccountCard';
import Swal from 'sweetalert2';
import { mapState, mapGetters } from 'vuex';

export default {
  page: {
    title: 'Dashboard',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    OrderHistory,
    AccountTransfers,
    BtcIcon,
    EthIcon,
    CoinAccountCard,
    CurrencyIcon,
  },
  data(self = this) {
    return {
      title: self.$t('Menu.Home'),
      items: [
        {
          text: 'Home',
          active: true,
        },
      ],
    };
  },
  computed: {
    ...authComputed,
    ...mapState('accounts', { loadingAccounts: (state) => state.loading, accounts: (state) => state.accounts }),
    ...mapState('auth', ['loading', 'balance', 'balanceRate', 'visibleBalance']),
    ...mapGetters('auth', ['userBalanceExchanged']),
    realAccounts() {
      return this.accounts.filter((acc) => !acc.is_demo);
    },
    isAllRealAccountsSameCurrency() {
      if (!this.realAccounts.length) {
        return true;
      }

      const firstAccount = this.realAccounts[0];

      if (this.currentUser.base_currency.key !== firstAccount.currency.key) {
        return false;
      }

      return this.realAccounts.every((acc) => acc.currency.key === firstAccount.currency.key);
    },
    summOfRealAccountsAmount() {
      if (!this.isAllRealAccountsSameCurrency) {
        return null;
      }

      return this.realAccounts
        .reduce((current, acc) => {
          return (current += acc.amount);
        }, 0)
        .toFixed(2);
    },
    uniqueCoins() {
      const currencyKeys = new Set();
      const uniqueCoins = [];

      this.accounts.forEach((coin) => {
        if (!currencyKeys.has(coin.currency.key)) {
          currencyKeys.add(coin.currency.key);
          uniqueCoins.push(coin);
        }
      });

      return uniqueCoins;
    },
    freeBalance() {
      let visibleBalance = this.balance?.amount;

      if (this.balance?.show_bonuses) {
        visibleBalance = visibleBalance - this.balance?.bonus;
      }

      if (visibleBalance < 0) {
        visibleBalance = 0;
      }

      return visibleBalance;
    },
    visibleBonuses() {
      let visibleBonuses = this.balance?.bonus;

      if (this.balance?.bonus > this.balance?.amount) {
        visibleBonuses = this.balance?.amount;
      }
      return visibleBonuses;
    },
    bonusesExchanged() {
      return (this.visibleBonuses * this.balanceRate).toFixed(2);
    },
    freeBalanceExchanged() {
      return (this.freeBalance * this.balanceRate).toFixed(2);
    },
  },
  methods: {
    showNotificationPopup(title, text, type, callback) {
      Swal.fire({
        title,
        html: `<div class="avatar-md mx-auto">
            <span class="avatar-title rounded-circle bg-${type} bg-opacity-10 text-${type} font-size-28">
              <i class="bx ${type === 'success' ? 'bx-wink-smile' : 'bx-sad'}"></i>
            </span>
          </div>
          <p class="text-muted mt-3 mb-3 font-size-14">${text}</p>`,
        showCloseButton: true,
        padding: '0 20px 60px 20px',
        confirmButtonText: 'Back to home',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-dark btn-sm w-lg me-2 mb-2',
          cancelButton: 'btn btn-outline-dark btn-sm w-lg me-2 mb-2',
          container: 'swal-padding',
        },
      }).then(() => {
        callback();
      });
    },
  },
  async mounted() {
    const queryParam = this.$route.query;
    if (queryParam.status) {
      this.showNotificationPopup(
        queryParam.status === 'success' ? 'Deposit made successfully' : 'Deposit made unsuccessfully',
        queryParam.message,
        queryParam.status === 'success' ? 'success' : 'danger',
        () => this.$router.push({ name: 'home' })
      );
    }
    this.$store.dispatch('auth/fetchBalance');
    this.$store.dispatch('auth/getBalanceRate');
  },
  metaInfo() {
    return {
      title: '',
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row match-height">
      <div class="col-xxl-4">
        <div class="card info-card">
          <div class="card-body">
            <div>
              <div class="mb-4 me-3">
                <i class="mdi mdi-account-circle h1"></i>
              </div>

              <div>
                <h2>{{ currentUser.first_name }} {{ currentUser.last_name }}</h2>
                <p class="text-muted mb-1">{{ currentUser.email }}</p>
                <p class="text-muted mb-0">{{ $t('Common.IdNo') }}: #{{ currentUser.id }}</p>
              </div>
            </div>
          </div>

          <div class="card-body border-top">
            <div class="row">
              <div v-if="currentUser?.show_full_balance" class="col-xxl-6 col-lg-4 col-sm-6">
                <div>
                  <p class="fw-medium mb-2">{{ $t('Transaction.Balance') }} :</p>
                  <b-spinner v-if="loading.balance"></b-spinner>
                  <h1 v-else-if="userBalanceExchanged" class="home-page__balance">
                    <template v-if="visibleBalance">{{ userBalanceExchanged }}&nbsp;</template>
                    <template v-else>***</template>{{ currentUser.base_currency.symbol }}
                  </h1>
                </div>

                <div>
                  <p class="fw-medium mb-2">{{ $t('Transaction.FreeBalance') }} :</p>
                  <b-spinner v-if="loading.balance"></b-spinner>
                  <h2 v-else-if="balance" class="home-page__balance">
                    <template v-if="visibleBalance">{{ isAllRealAccountsSameCurrency ? summOfRealAccountsAmount : freeBalanceExchanged }}</template>
                    <template v-else>***</template>
                    {{ currentUser.base_currency.symbol }}
                  </h2>
                </div>

                <div v-if="balance?.show_bonuses">
                  <p class="fw-medium mb-2">{{ $t('Transaction.Credit') }} :</p>
                  <b-spinner v-if="loading.balance"></b-spinner>
                  <h2 v-else-if="balance" class="home-page__balance">
                    <template v-if="visibleBalance">{{ bonusesExchanged }}</template
                    ><template v-else>***</template>{{ currentUser.base_currency.symbol }}
                  </h2>
                </div>
              </div>
              <div class="col-xxl-6 col-lg-4 col-sm-6">
                <div class="mt-4 mt-sm-0">
                  <p class="fw-medium mb-2">{{ $t('Transaction.Coin') }} :</p>
                  <div class="d-inline-flex align-items-center">
                    <b-spinner v-if="loadingAccounts.account"></b-spinner>
                    <span
                      v-for="account in uniqueCoins"
                      :key="account.id"
                      v-b-tooltip.hover
                      class="me-2"
                      data-placement="top"
                      :title="$t(`Currency.${account.currency.name}`)"
                    >
                      <BtcIcon v-if="account.currency.key === 'BTC'" :size="32" />
                      <EthIcon v-else-if="account.currency.key === 'ETH'" :size="32" />
                      <CurrencyIcon v-else :symbol="account.currency.symbol || account.currency.key" :size="32" />
                    </span>
                  </div>
                </div>
              </div>

              <span v-if="currentUser.base_currency.key !== 'USD' && !isAllRealAccountsSameCurrency" class="balance-ratio-warning text-error">
                {{ $t('Dashboard.BalanceRatioWarning') }}
              </span>
            </div>
          </div>

          <div class="card-footer bg-transparent border-top">
            <div>
              <router-link :to="{ name: 'deposit' }" class="btn btn-dark me-2 w-md">
                {{ $t('Accounts.Deposit') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-8">
        <div class="card deposits-card">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-end">
                <router-link type="button" class="btn btn-outline-dark fw-bold" :to="{ name: 'open-account' }">
                  {{ $t('Dashboard.OpenNewAccount') }}
                </router-link>
              </div>

              <h4 class="card-title">{{ $t('Dashboard.Deposits') }}</h4>
            </div>

            <b-spinner v-if="loadingAccounts.accounts" class="accounts-spinner"></b-spinner>

            <div class="row">
              <div v-for="account in accounts" :key="account.id" class="col-lg-4">
                <CoinAccountCard :coinAccount="account" />
              </div>
            </div>

            <div v-if="!accounts.length" class="no-accounts">
              <img src="@/assets/images/no_deposits.png" alt="no-deposits" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body dashboard__history-table">
        <b-tabs>
          <b-tab :title="$t('Dashboard.OrderHistory')" active lazy>
            <order-history :title="null" />
          </b-tab>

          <b-tab :title="$t('Transaction.Transfers')" lazy>
            <account-transfers :title="null" />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </Layout>
</template>

<style scoped lang="scss">
.home-page {
  &__balance {
    white-space: nowrap;
  }
}
.avatar-xs {
  flex: 0 0 auto;
}

::v-deep(.nav-tabs) {
  position: relative;
  z-index: 2;
}

::v-deep(.order-history-heading),
::v-deep(.account-transfers-heading) {
  margin-top: -50px;
  position: relative;
  z-index: 10;
  width: fit-content;
  margin-left: auto;

  @media (max-width: 575px) {
    margin-top: 0;
  }
}

.no-accounts {
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.accounts-spinner {
  position: absolute;
}
.balance-ratio-warning {
  font-size: 11px;
  color: #f94747;
}
</style>

<style lang="scss">
.app-rtl .order-history-heading,
.app-rtl .account-transfers-heading {
  margin-right: auto;
  margin-left: 0;
}
</style>
