<script>
import { mapState } from 'vuex';
import BtcIcon from '@/components/common/currency-icons/btc';
import EthIcon from '@/components/common/currency-icons/eth';
import CurrencyIcon from '@/components/common/currency-icons/currency';
import { CHART_OPTIONS } from '@/constants/apexChartSettings';
import AddDemoDepositPopup from '@/components/modals/AddDemoDepositPopup';

export default {
  name: 'CoinAccountCard',
  components: {
    BtcIcon,
    EthIcon,
    CurrencyIcon,
    AddDemoDepositPopup,
  },
  props: {
    coinAccount: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [
        {
          name: this.coinAccount.currency.key,
          data: [],
        },
      ],
      chartOptions: CHART_OPTIONS,
      loading: {
        statistic: false,
      },
      showDepositModal: false,
    };
  },
  computed: {
    ...mapState('auth', ['visibleBalance']),
    nomalizedSeries() {
      const dataSeries = this.series[0].data;

      if (!dataSeries.length) {
        dataSeries.length = 2;
        dataSeries.fill(0);
      }

      if (dataSeries.length === 1) {
        dataSeries.length = 2;
        dataSeries.fill(dataSeries[0]);
      }

      return [{ ...this.series[0], data: dataSeries }];
    },
  },
  methods: {
    openAccount() {
      this.$router.push({ name: 'accounts-show', params: { id: this.coinAccount.id } });
    },
    openDepositModal() {
      this.showDepositModal = true;
    },
    fetchCoinAccountStatistic() {
      this.loading.statistic = true;
      this.axios
        .get(`accounts/${this.coinAccount.id}/statistics/balance`)
        .then(({ data }) => {
          this.series[0].data = data;
        })
        .finally(() => {
          this.loading.statistic = false;
        });
    },
  },
  mounted() {
    this.fetchCoinAccountStatistic();
  },
};
</script>

<template>
  <div class="coin-account-card border p-3 rounded mt-4 cursor-pointer" @click="openAccount()">
    <div class="row">
      <div class="col-6">
        <div class="d-flex align-items-center mb-3">
          <div class="me-3">
            <BtcIcon v-if="coinAccount.currency?.key === 'BTC'" :size="32" />
            <EthIcon v-else-if="coinAccount.currency?.key === 'ETH'" :size="32" />
            <CurrencyIcon v-else :symbol="coinAccount.currency?.symbol || coinAccount.currency?.key" :size="32" />
          </div>
          <h5 class="font-size-14 mb-0">{{ coinAccount.currency?.name }}</h5>
        </div>
      </div>
      <div class="col-6">
        <div v-if="loading.statistic" class="d-flex justify-content-end">
          <b-spinner small></b-spinner>
        </div>
        <div v-else>
          <apexchart
            class="apex-charts"
            height="40"
            type="area"
            dir="ltr"
            :series="nomalizedSeries"
            :options="chartOptions"
          ></apexchart>
        </div>
      </div>
    </div>

    <div class="row"></div>

    <div class="row">
      <div class="col-lg-6">
        <div class="text-muted mt-3">
          <span
            v-if="coinAccount.is_demo"
            class="coin-account-card__demo-badge badge bg-primary bg-soft text-primary mb-3"
          >
            {{ $t('Transaction.Demo') }}</span
          >
          <span v-else class="coin-account-card__demo-badge badge bg-success bg-soft text-success mb-3">
            {{ $t('Transaction.Real') }}</span
          >
          <h5 class="coin-account-card__name" data-placement="top" v-b-tooltip.hover :title="coinAccount.name">
            {{ coinAccount.name }}
          </h5>
          <p class="mb-0">
            <template v-if="visibleBalance">{{ coinAccount.amount }}</template
            ><template v-else>***</template> {{ coinAccount.currency.key }}
          </p>
        </div>
      </div>

      <div class="col-lg-6 align-self-end">
        <div class="float-end mt-3">
          <button v-if="coinAccount?.is_demo" class="btn btn-dark" @click.stop="openDepositModal">
            {{ $t('Accounts.Deposit') }}
          </button>
          <router-link v-else @click.stop :to="{ name: 'deposit' }" class="btn btn-dark">{{
            $t('Accounts.Deposit')
          }}</router-link>
        </div>
      </div>
    </div>

    <AddDemoDepositPopup
      v-if="showDepositModal"
      :account="{ ...coinAccount, accountLeverage: coinAccount.leverage }"
      @closeModal="showDepositModal = false"
    />
  </div>
</template>

<style lang="scss" scoped>
.coin-account-card {
  &__demo-badge {
    width: 63px;
  }
  &__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
